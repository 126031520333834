import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { RouteConfig, ROUTES } from "./config";
import { clearAuthTokenFromLocalStorage, getAuthTokenFromLocalStorage } from "hooks/useAuthToken";
import userService from "api/user/user.service";
import { useDispatch } from "react-redux";
import { setCheckingToken, setIsAuthorized, setUser } from "store/commonSlice";
import MobileWarningPageBase from "./MobileWarningPage";
import AuthorizationPage from "./AuthorizationPage";
import { useLocalStorage } from "hooks/useLocalStorage";
import { CLIENT_ID_LOCAL_STORAGE_KEY } from "utils/constants";
import { showSnackbar } from "store/snackbarSlice";

const NotFound = React.lazy(() => import("../pages/NotFound"));

const getRoute = (route: RouteConfig) => {
  const Component = route.component;
  const Layout = route.layout ?? React.Fragment;
  const MobileWarningPage = route.isResponsive ? React.Fragment : MobileWarningPageBase;

  return (
    <Route
      key={route.path}
      path={route.path}
      element={
        <ErrorBoundary>
          <AuthorizationPage requireLogin={route.requireLogin} paidPlanNeeded={route.paidPlanNeeded}>
            <MobileWarningPage>
              <Layout {...route.layoutProps}>
                <React.Suspense fallback={null}>
                  <Component />
                </React.Suspense>
              </Layout>
            </MobileWarningPage>
          </AuthorizationPage>
        </ErrorBoundary>
      }
    >
      {route?.children?.map((child) => getRoute(child))}
    </Route>
  );
};

export const EventDispatchLogout = "RefabricDispatchLogout";
export const EventDispatchSnackbar = "RefabricDispatchSnackbar";

const Router = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (getAuthTokenFromLocalStorage() !== null)
      userService
        .tokenControl({ client_id: useLocalStorage().getItem(CLIENT_ID_LOCAL_STORAGE_KEY) })
        .then((res: any) => {
          if (res.responseCode === 402) {
            // 402 means user doesn't have a paid plan
            dispatch(
              setUser({
                id: res?.responseData?.uid,
                email: res.responseData?.username ?? res.responseData?.email,
              })
            );
            dispatch(setIsAuthorized(false));
            dispatch(setCheckingToken(false));

            return;
          }
          if (res.responseData.uid) {
            dispatch(setIsAuthorized(true));
            dispatch(setCheckingToken(false));

            dispatch(
              setUser({
                id: res?.responseData?.uid,
                email: res.responseData?.username ?? res.responseData?.email,
                packageCode: res.responseData?.pt,
                use_ghostphotoshoot: res.responseData?.use_ghostphotoshoot === 1,
                use_mannequin: res.responseData?.use_mannequin === 1,
              })
            );
          } else {
            dispatch(setIsAuthorized(false));
            dispatch(setCheckingToken(false));
            window.dispatchEvent(new CustomEvent(EventDispatchLogout));
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(setIsAuthorized(false));
          dispatch(setCheckingToken(false));
          window.dispatchEvent(new CustomEvent(EventDispatchLogout));
        });
    else dispatch(setCheckingToken(false));
  }, []);

  React.useEffect(() => {
    const detectPermissionDenied = () => {
      clearAuthTokenFromLocalStorage();
      useLocalStorage().removeItem(CLIENT_ID_LOCAL_STORAGE_KEY);
      window.location.href = "/";
    };
    window.addEventListener(EventDispatchLogout, detectPermissionDenied);

    const handleEvent = (e: any) => {
      switch (e.detail.type) {
        case 402:
          dispatch(showSnackbar({ type: 402 }));
          break;
        case 409:
          dispatch(showSnackbar({ type: 409 }));
          break;
        case 503:
          dispatch(showSnackbar({ type: 503, message: e.detail.message }));
          break;
        default:
          break;
      }
    };
    window.addEventListener(EventDispatchSnackbar, handleEvent);

    return () => {
      window.removeEventListener(EventDispatchLogout, detectPermissionDenied);
      window.removeEventListener(EventDispatchSnackbar, detectPermissionDenied);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map((route) => getRoute(route))}

        <Route
          path="*"
          element={
            <ErrorBoundary>
              <React.Suspense fallback={null}>
                <NotFound />
              </React.Suspense>
            </ErrorBoundary>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
