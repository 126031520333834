import React, { useState } from "react";
import { IconCamera, IconCollection, IconDesign, IconExplore, IconHome, IconMenu, IconMenuClose, IconMenuOpen, IconSharedWithMe, IconTrain, LogoRefabric } from "icons";
import UserProfile from "components/UserProfile/UserProfile";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { setCollectionFolders, setIsMainMenuFixed, setUser } from "store/commonSlice";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { PATHS } from "router/config/paths";
import userService from "api/user/user.service";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsTablet } from "hooks/useIsTablet";
import { useClickOutside } from "hooks/useClickOutside";
import Button from "components/Button";

const Menu = ({ isFixed }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const { isMainMenuFixed, user, isAuthorized } = useAppSelector((state) => state.common);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const containerRef = React.useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    setIsHovered(false);
  });

  const isSelected = React.useMemo(
    () => (item: any) => {
      if (item.path === "/collections") return location.pathname.includes(item.path);

      return item.path === location.pathname;
    },
    [location.pathname]
  );

  function handleHover() {
    if (isFixed) return;
    setIsHovered((prev) => !prev);
  }

  React.useEffect(() => {
    if (user.id === "" || user.id === undefined) return;

    userService
      .getUserDetail({
        user_id: user.id,
      })
      .then((res) => {
        dispatch(
          setUser({
            id: res?.responseData?.user_info.id,
            name: res?.responseData?.user_info.name,
            email: res?.responseData?.user_info.email,
            createPeriod: res.responseData?.last_created_info.created_period,
            profilePhoto: res.responseData?.user_info.profile_photo,
            packageCode: res.responseData?.package_detail?.code,
            use_ghostphotoshoot: res.responseData?.package_detail?.use_ghostphotoshoot === "True",
            use_mannequin: res.responseData?.package_detail?.use_mannequin === "True",
          })
        );
      });
  }, [user.id]);

  const menuItems = [
    {
      title: "Home",
      disabled: !isAuthorized,
      path: PATHS.HOME,
      icon: <IconHome className="w-[18px] h-[18px]" />,
      onClick: () => {
        navigate(PATHS.HOME);
      },
    },
    {
      title: "Discover",
      disabled: !isAuthorized,
      path: PATHS.DISCOVER,
      icon: <IconExplore className="w-[18px] h-[18px]" />,
      onClick: () => {
        navigate(PATHS.DISCOVER);
      },
    },
    {
      title: "Design History",
      disabled: !isAuthorized,
      path: PATHS.DESIGN_HISTORY,
      icon: <IconDesign className="w-[18px] h-[18px]" />,
      onClick: () => {
        navigate(PATHS.DESIGN_HISTORY);
      },
    },
    {
      title: "My Collections",
      disabled: !isAuthorized,
      path: "/collections",
      icon: <IconCollection className="w-[18px] h-[18px]" />,
      onClick: () => {
        dispatch(setCollectionFolders(["Collections"]));
        navigate("/collections");
      },
    },
    {
      title: "Trainings",
      disabled: !isAuthorized,
      path: "/trainings",
      icon: <IconTrain className="w-[18px] h-[18px]" />,
      onClick: () => {
        navigate("/trainings");
      },
    },
    {
      title: "Photoshoots",
      disabled: !isAuthorized,
      path: PATHS.PHOTOSHOOTS,
      icon: <IconCamera className="w-[18px] h-[18px]" />,
      onClick: () => {
        navigate(PATHS.PHOTOSHOOTS);
      },
    },
    // {
    //   title: "Explore",
    //   path: "/explore",
    //   icon: <IconExplore className="w-[18px] h-[18px]" />,
    //   onClick: () => {
    //     navigate("/explore");
    //   },
    // },
    // {
    //   title: "Shared with Me",
    //   path: PATHS.Shared,
    //   icon: <IconSharedWithMe className="w-[18px] h-[18px]" />,
    //   onClick: () => {
    //     navigate(PATHS.Shared);
    //   },
    // },
  ];

  return (
    <div className="flex relative items-center">
      <div className="flex-center h-[56px] w-[56px] p-2 border-r border-bg-3">
        <IconMenu className="cursor-pointer text-grey w-5 h-5" onMouseEnter={handleHover} onClick={handleHover} />
      </div>
      <div
        ref={containerRef}
        className={clsx(
          "flex w-[220px] absolute top-0  h-screen flex-col gap-5 z-50 bg-bg-2 transition-transform duration-500  border-r border-bg-3",
          isMainMenuFixed || isFixed ? "" : !isHovered ? "-translate-x-full" : "transalte-x-10"
        )}
        onMouseLeave={() => {
          if (!isMainMenuFixed) handleHover();
        }}
      >
        <div className="flex items-center border-b border-bg-3 gap-[15px] h-[56.5px]">
          <div className={clsx("flex-center h-[56px] w-[56px] p-2 border-r border-bg-3", isFixed ? "pointer-events-none" : "")}>
            {!isMainMenuFixed ? (
              <IconMenuOpen
                className="cursor-pointer w-5 h-5 text-grey"
                onClick={() => {
                  if (isMobile || isTablet) setIsHovered(false);
                  else dispatch(setIsMainMenuFixed(true));
                }}
              />
            ) : (
              <IconMenuClose
                className="cursor-pointer w-5 h-5 text-grey"
                onClick={() => {
                  if (isMobile || isTablet) return;
                  dispatch(setIsMainMenuFixed(false));
                }}
              />
            )}
          </div>
          <LogoRefabric />
        </div>
        <div className="flex w-full px-3">
          <UserProfile />
        </div>
        <div className="flex flex-col px-4">
          {menuItems.map((item: any) => (
            <Button
              key={item.title}
              disabled={item.disabled}
              className={clsx(
                "flex justify-start w-full h-[32px] items-center text-grey gap-2.5 py-2.5  rounded pl-2",
                item.disabled ? "pointer-events-none" : "cursor-pointer hover:text-green",
                isSelected(item) ? "!text-green bg-bg-3" : ""
              )}
              onClick={() => {
                item.onClick();
              }}
            >
              {item.icon}
              <span className="text-menu">{item.title}</span>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Menu);
