export enum PATHS {
  HOME = "/",
  AUTH = "/auth",
  LOGIN = "/login/:productId?/:priceId?",
  SIGNUP = "/signup/:productId?/:priceId?",

  PHOTOSHOOT = "/photoshoot/:id?",
  PHOTOSHOOTS = "/photoshoots",

  DESIGN = "/design/:id?",
  TRAININGS = "/trainings/:id?",
  NEW_TRAINING = "/new-training/:id?",
  COLLECTIONS = "/collections/:id?",
  DESIGN_HISTORY = "/design-history",
  DISCOVER = "/discover",
  ACCOUNT = "/account",
  Shared = "/shared",
  EXPLORE = "/explore",
  EXPLORE_NEWS = "/explore/news",
  EXPLORE_TUTORIALS = "/explore/tutorials",
  RESET_PASSWORD = "/reset-password/:token",
  EMAIL_VERIFY = "/email-verify/:token/:productId?/:priceId?",
  PAYMENT_SUCCESS = "/payment/success/:orderId",
  PAYMENT_FAIL = "/payment/error/:orderId",
  PRICING = "/pricing",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_USE = "/terms-of-use",
  ACCEPT_INVITE = "/accept_invite/:token",

  CREATE = "/create",
  HISTORY = "/history",
  FAVORITES = "/favorites",
  POST = "/posts/:id",
  Promo = "/promo/:token",
}
