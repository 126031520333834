import clsx from "clsx";
import Button from "components/Button";
import { useClickOutside } from "hooks/useClickOutside";
import { useIsMobile } from "hooks/useIsMobile";
import { IconCamera, IconDesign, IconPlus, IconTrain } from "icons";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { AnalyticsEvent } from "utils";

const CommonHeader = ({ isFixed }: any) => {
  const navigate = useNavigate();
  const { isMainMenuFixed, isAuthorized } = useAppSelector((state) => state.common);
  const isMobile = useIsMobile();
  const [showMenu, setShowMenu] = React.useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  const menuItems = [
    {
      title: "New Design",
      icon: <IconDesign className="w-[18px] h-[18px] flex-shrink-0" />,
      onClick: () => {
        AnalyticsEvent("Start_New", "", "click", { panel: "design" });
        navigate("/design");
      },
    },
    {
      title: "New Training",
      icon: <IconTrain className="w-[18px] h-[18px] flex-shrink-0" />,
      onClick: () => {
        AnalyticsEvent("Start_New", "", "click", { panel: "training" });
        navigate("/new-training");
      },
    },
    {
      title: "New Photoshoot",
      icon: <IconCamera className="w-[18px] h-[18px] flex-shrink-0" />,
      onClick: () => {
        AnalyticsEvent("Start_New", "", "click", { panel: "photoshoot" });
        navigate("/photoshoot");
      },
    },
  ];

  const StartNewMenu = () => {
    return (
      <div className="flex flex-col w-[240px] p-1 border border-bg-3 bg-bg rounded-[5px]" ref={menuRef}>
        {menuItems.map((item, index) => (
          <button key={index} className="flex items-center w-full gap-2.5 text-bodyMd text-white hover:bg-green hover:bg-opacity-10 hover:text-green rounded p-2" onClick={item.onClick}>
            {item.icon}
            {item.title}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex w-full justify-between px-5">
      <div className={clsx("flex w-full transition-all duration-500", isFixed || isMainMenuFixed ? "" : "")}>{/* <Search /> */}</div>
      <div className="relative flex">
        <Button
          className="btn-primary-small w-[144px] xs:!w-fit !bg-green hover:!bg-purple"
          disabled={!isAuthorized}
          onClick={() => {
            AnalyticsEvent("header_start_new", "", "click");
            setShowMenu(true);
          }}
        >
          {isMobile ? (
            <IconDesign className="w-[18px] h-[18px]" />
          ) : (
            <>
              <IconPlus className="w-5 h-5" />
              Start New
            </>
          )}
        </Button>
        {showMenu && (
          <div className="absolute top-11 right-0 bg-bg-2 z-[9990]">
            <StartNewMenu />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonHeader;
